import { Typography } from "@material-tailwind/react";
import poweredby from "../images/Poweredby2blk.png";
import poweredby2 from "../images/Poweredby1blk.png";
 
export default function SimpleFooter() {
  return (
    <footer className="flex w-full flex-row flex-wrap bg-gardenblue items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 px-6 text-center md:justify-between">
      <Typography color="blue-gray" className="font-normal">
        &copy; 2023 Garden City Roleplay
      </Typography>

      <img alt="poweredby" class="w-[200px] hidden md:block" src={poweredby}/>
      <img alt="poweredby" class="w-[80px] block md:hidden" src={poweredby2}/>
    </footer>
  );
}