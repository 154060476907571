import { Button } from "@material-tailwind/react";

export function ButtonDefault() {
	return (
		<a href="https://discord.gg/gardencity" target="_balank">
		<Button class="px-5 py-2 rounded-lg bg-buttonblue text-white font-bold">
			APPLY TODAY
		</Button>
		</a>
	);
}
