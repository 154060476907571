import "./App.css";
import Home from "./pages/Home";
import NavbarSimple from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
	return (
		<div class="flex flex-col justify-center items-center md:overflow-hidden bg-bgprimary w-auto scroll-smooth">
			<div class="w-full bg-gardenblue">
				<NavbarSimple fullWidth="True" />
			</div>
			<Home />
			<Footer />
		</div>
	);
}

export default App;
