import React from "react";
import { ButtonDefault } from "../components/Button";
import discord from "../images/discord.png";
import teamspeak from "../images/teamspeak.png";
import tiktok from "../images/tiktok.png";
import gardencityhero from "../images/gardencityhero.jpg";
import merryweather from "../images/merryweathersecurity.webp";
import lscustoms from "../images/lscustoms.webp";
import twofour from "../images/V247.webp";
import rebsliquore from "../images/rebsliquor.webp";
import chicagocar from "../images/chicago-police-car.webp";
import jointhepolice from "../images/jointhepolice.jpg";
import gtacop from "../images/officerofthemonth.png";

export default function Home() {
	return (
		<div class="flex w-full items-center flex-col">
			<section
				id="section1"
				class="flex flex-col justify-center items-center text-center w-full md:px-10 bg-offwhite bg-cover"
			>
				<div class="flex flex-col items-center justify-center max-w-[1140px] w-full">
					<div class="flex flex-col justify-center items-center text-center h-full w-full md:w-2/3 pb-10">
						<div class="font-oswald bold text-5xl w-full py-10 md:text-6xl font-bold-">
							Garden City Roleplay
						</div>
						<img alt="heroimg"
							class="shadow-xl md:rounded-2xl"
							src={gardencityhero}
						/>
						<div class="font-ebgram pt-10 px-2 md:px-0 text-xl w-full">
							Join the ranks of those who stand for justice,
							protect the innocent, and uphold the law. Become a
							guardian of your community's safety.
						</div>
					</div>
					<div class="w-full md:w-1/2">
						<div class="w-full pb-10">
							<ButtonDefault />
						</div>
					</div>
				</div>
			</section>

		{/* SOCIALS SECTION */}

			<section
				id="section2"
				class="flex w-full justify-center items-center bg-gardenblue"
			>
				<div class="grid grid-rows-3 md:grid-rows-1 md:grid-cols-3 auto-rows-fr gap-8 w-4/5 md:w-full max-w-[1140px] md:justify-apart items-center px-12 md:px-20 py-5 md:py-16 ">
					<div class="flex flex-col md:h-full justify-center md:justify-between items-center text-center md:text-start rounded-2xl bg-white font-oswald">
						<a href="ts3server://gardencity?port=9987" target="_blank" rel="noreferrer">
						<img alt="teamspeak" class="w-full rounded-t-2xl" src={teamspeak} />
						<div class="px-10">
							<div class="flex justify-center text-2xl font-bold py-5">
								TEAM SPEAK
							</div>
						</div>
						</a>
					</div>
					<div class="flex flex-col h-full justify-between items-center text-center md:text-start rounded-2xl bg-white font-oswald">
						<a href="https://discord.gg/6SbPQjRyct" target="_blank" rel="noreferrer">
							<img alt="discord" class="w-full rounded-t-2xl" src={discord} />
							<div class="px-10">
								<div class="flex justify-center text-2xl font-bold py-5">
									DISCORD
								</div>
							</div>
						</a>
					</div>
					<div class="flex flex-col h-full justify-between items-center text-center md:text-start rounded-2xl bg-white font-oswald">
						<a
							href="https://www.tiktok.com/@gardencityfivem"
							target="_blank" rel="noreferrer"
						>
							<img alt="tiktok" class="w-full rounded-t-2xl" src={tiktok} />
							<div class="px-10">
								<div class="flex justify-center text-2xl font-bold py-5">
									TIKTOK
								</div>
							</div>
						</a>
					</div>
				</div>
			</section>

			{/* SOCIALS SECTION END*/}

			{/* OFFICER OF THE MONTH SECTION */}

			<section
				id="section3"
				class="flex w-full justify-center items-center bg-offwhite2 py-5 md:py-10 px-5 md:px-0"
			>
				<div class="flex flex-col-reverse md:flex-row w-full max-w-[1140px] text-center md:text-start justify-evenly pb-5">
					<div class="flex flex-col w-full md:w-1/2 justify-center">
						<div class="text-4xl font-bold md:w-4/5 pt-10 font-oswald">
							OFFICER OF THE MONTH
						</div>
						<div class="text-3xl font-bold md:w-4/5 pt-5">
							Weston Woods
						</div>
						<div class="text-xl pt-5 font-ebgram">
							Your dedication, professionalism, and exemplary
							service to our community do not go unnoticed. Your
							commitment to keeping our streets safe and fostering
							positive relationships is truly commendable. Thank
							you for your outstanding contributions. Keep up the
							excellent work!
						</div>
					</div>
					<div class="flex justify-center md:justify-end w-full md:w-2/6">
						<img alt="gtacop" class="rounded-2xl" src={gtacop} />
					</div>
				</div>
			</section>

						{/* OFFICER OF THE MONTH SECTION END*/}



			<section
				id="section4"
				class="flex w-full justify-center items-center bg-gardenblue"
			>
				<div class="flex flex-col w-full max-w-[1140px] justify-center items-center px-2 md:px-0 py-5 md:py-14">
					<div class="text-5xl pb-10 font-bold font-oswald">
						Trusted by
					</div>
					<div class="flex flex-row flex-wrap w-full md:flex-nowrap md:justify-evenly justify-around">
						<div class="flex w-2/5 h-auto items-center justify-center md:w-full md:h-[100px]">
							<img alt="merryweather" class="w-auto h-full " src={merryweather} />
						</div>
						<div class="flex w-2/5 h-auto items-center justify-center md:w-full md:h-[100px]">
							<img alt="lscustom" class="w-auto h-full " src={lscustoms} />
						</div>
						<div class="flex w-2/5 h-auto items-center justify-center md:w-full md:h-[100px] mt-5 md:mt-0">
							<img alt="twofour" class="w-auto h-4/5 " src={twofour} />
						</div>
						<div class="flex w-2/5 h-auto items-center justify-center md:w-full md:h-[100px] mt-5 md:mt-0">
							<img alt="rebsliquore" class="w-auto h-full " src={rebsliquore} />
						</div>
					</div>
				</div>
			</section>


			{/* <section
				id="section4"
				class="flex w-full justify-center items-center bg-gardenblue"
			>
				<div class="flex flex-col w-full max-w-[1140px] justify-center items-center px-2 md:px-0 py-5 md:py-14">
					<div class="text-5xl pb-10 font-bold font-oswald">
						Trusted by
					</div>
					<div class="grid grid-cols-2 md:grid-cols-4 w-full gap-16 w-fill">
						<div class="flex w-full h-[100px] items-center justify-center">
							<img alt="merryweather" class="w-auto h-full " src={merryweather} />
						</div>
						<div class="flex w-full h-full items-center justify-center ">
							<img alt="lscustom" class="w-auto h-full " src={lscustoms} />
						</div>
						<div class="flex w-full h-full items-center justify-center ">
							<img alt="twofour" class="w-auto h-4/5 " src={twofour} />
						</div>
						<div class="flex w-full h-full items-center justify-center ">
							<img alt="rebsliquore" class="w-auto h-full " src={rebsliquore} />
						</div>
					</div>
				</div>
			</section> */}

			<section
				id="section5"
				class="flex w-full justify-center items-center bg-offwhite2 pt-5 md:py-10 px-5 md:px-0"
			>
				<div class="flex flex-col-reverse md:flex-row w-full max-w-[1140px] text-center md:text-start justify-evenly pb-5">
					<div class="flex flex-col w-full md:w-1/2 justify-center">
						<div class="text-4xl font-bold md:w-4/5 pt-10 font-oswald">
							Our commitment
						</div>
						<div class="text-xl pt-10 font-ebgram">
							At Garden City, we are dedicated to
							serving and protecting our community with integrity,
							professionalism, and compassion. Our commitment
							extends beyond enforcing laws; it encompasses
							building trust, fostering partnerships, and
							promoting a safe and inclusive environment for all.
						</div>
					</div>
					<div class="flex justify-center md:justify-end w-full md:w-1/2">
						<img alt="chiacgocar" class="rounded-2xl" src={chicagocar} />
					</div>
				</div>
			</section>

			<section
				id="section6"
				class="flex w-full justify-center items-center bg-offwhite2 py-5 md:py-10 px-5 md:px-0"
			>
				<div class="flex flex-col-reverse  md:flex-row-reverse w-full max-w-[1140px] text-center md:text-start justify-evenly pb-5">
					<div class="flex flex-col w-full md:w-1/2 justify-center">
						<div class="text-4xl font-bold md:w-4/5 pt-10 font-oswald">
							Make a Difference
						</div>
						<div class="text-xl pt-10 font-ebgram">
							Joining the police isn't just a job—it's a calling.
							You'll be part of a team that works together to
							maintain public safety, uphold justice, and build
							trust within our community. From patrolling the
							streets to investigating crimes, every day will
							bring new opportunities.
						</div>
					</div>
					<div class="flex  justify-center md:justify-start w-full md:w-1/2">
						<img alt="tiktok" class="rounded-2xl" src={jointhepolice} />
					</div>
				</div>
			</section>
		</div>
	);
}
